import * as React from 'react'
import * as css from './TextWithIcon.module.css'
import Icon from 'components/common/Icon'
import SanityBlock from './SanityBlock'
import clsx from 'clsx'

const TextWithIcon = ({ title, _rawDescription, icon, className }) => (
  <div className={clsx(className, 'richtext')}>
    {icon && <Icon className={css.icon} name={icon} />}

    <div className="richtext">
      {title && <h2 className="h2">{title}</h2>}
      {_rawDescription && <SanityBlock body={_rawDescription} />}
    </div>
  </div>
)

export default TextWithIcon
