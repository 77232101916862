import * as React from 'react'
import * as css from './Hero.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Hero = ({ pretitle, _rawTitle, image }) => (
  <section className={css.root}>
    <div className={css.bg} />

    <div className={`${css.txt} padding-x padding-y`}>
      <div className="container-sm richtext">
        <p className="uppercase">{pretitle}</p>

        <SanityBlock body={_rawTitle} className={css.title} />
      </div>
    </div>

    <div className={`${css.img} padding-x`}>
      <figure className="styled-corner-left styled-corner-right">
        <GatsbyImage
          className="shadow"
          image={getImage(image.asset)}
          objectFit="contain"
          alt={pretitle}
        />
      </figure>
    </div>
  </section>
)

export default Hero
