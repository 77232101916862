import * as React from 'react'
import * as css from './BottomSection.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'

const BottomSection = ({ items, image }) => (
  <section className="padding-y">
    {image && (
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt=""
        objectFit="cover"
      />
    )}

    <ul className={`${css.list} container padding-x padding-y`}>
      {items.map((item, key) => (
        <li key={key}>
          <h2 className={`${css.title} h3`}>{item.title}</h2>
          <SanityBlock body={item._rawDescription} />
        </li>
      ))}
    </ul>
  </section>
)

export default BottomSection
