import * as React from 'react'
import * as css from './IconList2.module.css'
import nl2br from 'utils/nl2br'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import TextWithIcon from 'components/common/TextWithIcon'

const IconList2 = ({ title, description, iconList, image1, image2 }) => (
  <section className={`${css.root} container padding`}>
    <header className="richtext">
      {title && <h2 className="h1">{title}</h2>}
      {description && <p>{nl2br(description)}</p>}
    </header>

    <GatsbyImage
      image={getImage(image1.image.asset)}
      alt={image1.alt || ''}
    />

    <GatsbyImage
      className="desktop-and-up"
      image={getImage(image2.image.asset)}
      alt={image2.alt || ''}
    />

    <div className={`${css.list} grid`}>
      {iconList?.items.map((item, key) => (
        <TextWithIcon {...item} key={key} />
      ))}
    </div>
  </section>
)

export default IconList2
