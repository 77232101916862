import * as React from 'react'
import clsx from 'clsx'

const Divider = ({ wide, lg }) => (
  <section className={clsx(wide ? 'container-lg' : 'container', 'padding-x')}>
    <hr className={clsx(lg && 'lg')} />
  </section>
)

export default Divider
