import * as React from 'react'
import * as css from './ToolsSection.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ToolsSection = ({ title, _rawDescription, image }) => (
  <section className="container padding text-center">
    <header className={`${css.header} richtext`}>
      {title && <h2 className="h1">{title}</h2>}

      {_rawDescription && <SanityBlock body={_rawDescription} />}
    </header>

    {image && (
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={title}
      />
    )}
  </section>
)

export default ToolsSection
