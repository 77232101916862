import * as React from 'react'
import * as css from './IconList.module.css'
import TextWithIcon from 'components/common/TextWithIcon'

const IconList = ({ items }) => (
  <section className={`${css.root} container padding-x padding-y`}>
    {items?.map((item, key) => (
      <TextWithIcon {...item} className={css.item} key={key} />
    ))}
  </section>
)

export default IconList
