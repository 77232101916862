import * as React from 'react'
import * as css from './LeadershipTeam.module.css'
import EmployeePhoto from 'components/employee/EmployeePhoto'

const LeadershipTeam = ({ title, team }) => (
  <section className={`${css.root} container padding-x padding-y`}>
    <h2 className={`${css.heading} h1`}>{title}</h2>

    {team.employees?.map((employee, key) => (
      <div key={key}>
        <EmployeePhoto {...employee} />

        <div className={`${css.name} uppercase`}>
          {[employee.name, employee.designation].filter(Boolean).join`, `}
        </div>

        <div className={`${css.title} uppercase`}>{employee.title}</div>
      </div>
    ))}
  </section>
)

export default LeadershipTeam
