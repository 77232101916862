import * as React from 'react'
import * as css from './SupportSection.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SupportSection = ({ title, subtitle, _rawDescription1, _rawDescription2, image }) => (
  <section>
    <div className="container padding">
      <header className={css.header}>
        {title && <h2 className="h1">{title}</h2>}
        {subtitle && <p className={css.subtitle}>{subtitle}</p>}
      </header>

      <SanityBlock className={`${css.columns} ${css.avoid}`} body={_rawDescription1} />
    </div>

    {image && (
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={title}
        objectFit="cover"
        draggable="false"
      />
    )}

    <div className="container padding">
      <SanityBlock className={css.columns} body={_rawDescription2} />
    </div>
  </section>
)

export default SupportSection
