import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql, useStaticQuery } from 'gatsby'
import Hero from 'components/pg-about/Hero'
import IconList from 'components/pg-about/IconList'
import Divider from 'components/common/Divider'
import ToolsSection from 'components/pg-about/ToolsSection'
import SupportSection from 'components/pg-about/SupportSection'
import QuickLinks from 'components/common/QuickLinks'
import IconList2 from 'components/pg-about/IconList2'
import LeadershipTeam from 'components/pg-about/LeadershipTeam'
import BottomSection from 'components/pg-about/BottomSection'

const AboutPg = () => {
  const { sanityAboutPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <Hero {...pg.hero} />
      <IconList {...pg.iconList} />
      <Divider />
      <ToolsSection {...pg.toolsSection} />
      <SupportSection {...pg.supportSection} />
      <Divider />
      <QuickLinks>
        <h2 className="h3 text-center">{pg.quickLinks.title}</h2>
      </QuickLinks>
      <IconList2 {...pg.iconList2} />
      <LeadershipTeam {...pg.team} />
      <BottomSection {...pg.bottomSection} />
    </DefaultLayout>
  )
}

export default AboutPg

const query = graphql`query AboutPg {
  sanityAboutPg {
    hero {
      pretitle
      _rawTitle
      image { asset { gatsbyImageData(placeholder: NONE, width: 650) } }
    }
    iconList {
      items {
        title
        _rawDescription
        icon
      }
    }
    toolsSection {
      title
      _rawDescription
      image { asset { gatsbyImageData(placeholder: NONE, width: 900) } }
    }
    supportSection {
      title
      subtitle
      _rawDescription1
      _rawDescription2
      image { asset { gatsbyImageData(placeholder: NONE, height: 400) } }
    }
    quickLinks {
      title
    }
    iconList2 {
      title
      description
      iconList {
        items {
          title
          _rawDescription
          icon
        }
      }
      image1 {
        image { asset { gatsbyImageData(placeholder: NONE) } }
        alt
      }
      image2 {
        image { asset { gatsbyImageData(placeholder: NONE) } }
        alt
      }
    }
    team {
      title
      team { ...employees }
    }
    bottomSection {
      items {
        title
        _rawDescription
      }
      image { asset { gatsbyImageData(placeholder: NONE, height: 400) } }
    }
    seo {
      title
      description
    }
  }
}`
